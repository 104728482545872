/* eslint-disable no-var */
var messageShown = false;
var messageText = 'Hei, du må oppdatere nettleseren din for å lese videre.';

if (!messageShown) {
  messageShown = true;

  var message = document.createElement('DIV');
  document.body.appendChild(message);
  message.outerHTML =
    '<div id="old-browser" style="position: fixed; top: 100px; left: 0; right: 0; background: white; font-size: 32px; text-align: center; border: 2px solid black; margin: 10px; padding: 10px; z-index: 100000;">' +
    messageText +
    '<div style="font-family: sans-serif; cursor:pointer; position: absolute; right: 6px; top: 4px; font-size: 20px" onclick="this.parentElement.remove()">x</div></div>';
}

export {};
